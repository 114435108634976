.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.grow {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  transition: all 200ms;
}

.grow:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.icon-link {
  text-decoration: none;
}

.anchor {
  fill: white;
}

.heading:hover .anchor {
  fill: #24292e;
}


.social {
  font-size: 20px;
  letter-spacing: 5px;
}
.social a {
  color: currentColor;
}
.social a:hover {
  color: #3198D8;
}

.pill-link {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 10px 4px 0px;
  cursor: pointer;
  border-radius: 16px;
}

.pill-link:hover {
  background-color: #3198D8;
  color: white;
}
